import React, { createContext, useContext, useMemo } from "react";
import { useNavigate, Navigate } from "react-router-dom";
import { useLocalStorage } from "./useLocalStorage";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useLocalStorage("userOwnChat", null)
    const [jwt, setJwt] = useLocalStorage("token-ownchat", null)
    const navigate = useNavigate();

    const login = async (data) => {
        setUser(JSON.stringify(data.userName));
        setJwt(data.jwt);
        navigate("/home")
    }

    const logout = async () => {
        setUser(null)
        setJwt(null);
        navigate("/login")

    }
    const auth = useMemo(
        () => (
            {
                user,
                jwt,
                login,
                logout
            }),
        [user]
    );

    return (
        <AuthContext.Provider value={auth}>
            {children}
        </AuthContext.Provider>
    )
}

export const useAuth = () => {
    return useContext(AuthContext);
};

export const ProtectedComponents = (props) => {
    const auth = useAuth();
    if (!auth.user) {
        return <Navigate to="/login" />
    }
    return props.children
}