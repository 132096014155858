import React, { useState, useEffect } from 'react';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { InputTextarea } from 'primereact/inputtextarea';
import { Button } from 'primereact/button';
import { Divider } from 'primereact/divider';
import { Slider } from 'primereact/slider';
import { Checkbox } from 'primereact/checkbox';

// Data  
import { languages } from '../../../../shared/languages.js';
import { targetAudiences } from '../../../../shared/targetAudience.js';

// components
import { AudioPlayer } from '../../../../components/AudioPlayer/AudioPlayer.jsx';

// services
import { createOwnBots } from '../../service.js';

import './styles.css'

export const CreateOwnBot = ({ addOwnBot, chatBotOptions, voices }) => {
    // useStates
    const [visible, setVisible] = useState(false);                      //Dialog Visible 
    const [createLoading, setCreateLoading] = useState(false);          //createLoading
    const [createOwnBotForm, setCreateOwnBotForm] = useState(           // ownBotForm
        {
            name: '',
            chatbot: '',
            aiToken: '',
            description: '',
            language: '',
            targetAudience: '',
        }
    )
    const [selectedChatBotOption, setSelectedChatBotOption] = useState(null) //selectedChatbotOption
    const [selectedChatBotOptionValidation, setSelectedChatBotOptionValidation] = useState(false) //ChatBotOptionValidation

    const [targetAudience, setTargetAudience] = useState(false);

    // VoiceSettings Scroll
    const [voiceSettingTone, setVoiceSettingTone] = useState(0)
    const [voiceSettingSpeed, setVoiceSettingSpeed] = useState(0)
    const [voiceSettingVolumen, setVoiceSettingVolumen] = useState(0)

    const [selectedVoices, setSelectedVoices] = useState()
    const [voiceSettingStability, setVoiceSettingStability] = useState(50);
    const [voiceSettingClarity, setVoiceSettingClarity] = useState(50);
    const [voiceSettingStyle, setVoiceSettingStyle] = useState(50);
    const [voiceSettingSpeakerBoost, setVoiceSettingSpeakerBoost] = useState(false);


    const languagesFiltered = () => {
        const laguagesFilter = languages.sort((a, b) => {
            const languageA = a.language.toUpperCase();
            const languageB = b.language.toUpperCase();

            if (languageA < languageB) {
                return -1
            }
            if (languageA > languageB) {
                return 1;
            }
            return 0
        })

        return laguagesFilter;
    }


    const handleChange = event => {
        const { name, value } = event.target;
        setCreateOwnBotForm(prevFormData => ({
            ...prevFormData,
            [name]: value
        }))
    }

    const handleCleanForm = () => {
        setCreateOwnBotForm(
            {
                name: '',
                chatbot: '',
                aiToken: '',
                description: '',
                language: '',
                targetAudience: '',
            }
        );
        setTargetAudience(false);
        setSelectedChatBotOption(null);
        setVoiceSettingTone(0);
        setVoiceSettingSpeed(0);
        setVoiceSettingVolumen(0);
    }
    const handleQuitDialog = () => {
        handleCleanForm();
        setVisible(false);
    }
    const handlecreateOwnBot = async (e) => {
        e.preventDefault();
        setSelectedChatBotOptionValidation(false)
        if (selectedChatBotOption) {
            // targetAudience temporaly fix
            const targetAudienceConvertstring = (targetAudience) ? 'minor' : 'adult'

            try {
                setCreateLoading(true)
                e.preventDefault();

                // ToDo change voiceSettings 
                // remove tone, speed, volumen
                // add Stability, Clarity, style , speakerBoost
                // selectedVoices 
                const ownBotForm = {
                    name: createOwnBotForm.name,
                    chatbot: selectedChatBotOption.chatBotId,
                    aiToken: createOwnBotForm.aiToken,
                    description: createOwnBotForm.description,
                    language: createOwnBotForm.language.language,
                    // change targetAudience to bool
                    targetAudience: targetAudienceConvertstring,
                    // Remove past voiceSetting
                    tone: voiceSettingTone,
                    speed: voiceSettingSpeed,
                    volumen: voiceSettingVolumen,

                    // VoiceSettings
                    stability: voiceSettingStability / 100,
                    similarityBoost: voiceSettingClarity / 100,
                    style: voiceSettingStyle / 100,
                    useSpeakerBoost: voiceSettingSpeakerBoost,
                    voiceId: selectedVoices.voice_id,

                }
                // console.log(ownBotForm);
                const response = await createOwnBots(ownBotForm); //service
                addOwnBot(response); //def from Ownbot Component
                handleCleanForm();
                setCreateLoading(false);
                setVisible(false);
            } catch (error) {
                console.error(error);
            }
        }
        else {
            setSelectedChatBotOptionValidation(true)
        }
    }

    return (
        <>
            <div className='createOwnBotContainer' onClick={() => setVisible(true)}>
                <h2>Create OwnBot</h2>
                <i className='pi pi-plus'></i>
            </div>

            {/* Todo create OwnbotForm for share component */}
            <Dialog header="Create OwnBot" visible={visible} style={{ width: '50vw' }} onHide={handleQuitDialog} >
                <form onSubmit={handlecreateOwnBot} className='createOwnBotForm'>
                    {/* name */}
                    <div className='createOwnBotForm-name'>
                        <p>Name</p>
                        <InputText
                            type="text"
                            value={createOwnBotForm.name}
                            onChange={handleChange}
                            name='name'
                            className="p-inputtext-sm"
                            style={{ width: '100%' }}
                            required
                        />
                    </div>

                    <Divider align="left">
                        <span className="p-tag">Ai Settings</span>
                    </Divider>

                    {/* ai */}
                    <div className='createOwnBotForm-ai'>
                        <p>AI</p>
                        <Dropdown
                            value={selectedChatBotOption}
                            onChange={(e) => setSelectedChatBotOption(e.value)}
                            options={chatBotOptions}
                            optionLabel="name"
                            placeholder="Select an AI"
                            className="w-full md:w-14rem"
                            style={{ width: '100%' }}
                        />
                    </div>
                    {
                        selectedChatBotOptionValidation &&
                        <div style={{ 'display': 'flex', 'justifyContent': 'flex-end', 'marginBottom': '0.5rem' }} >
                            <small style={{ 'color': 'red' }}>AI Required</small>
                        </div>
                    }
                    {/* aiToken */}
                    <div className='createOwnBotForm-aiToken'>
                        <p>API Key</p>
                        <InputText
                            type='text'
                            value={createOwnBotForm.aiToken}
                            onChange={handleChange}
                            name='aiToken'
                            className='p-inputtext-sm'
                            style={{ width: '100%' }}
                            required
                        />
                    </div>

                    <Divider align="left">
                        <span className="p-tag">Ownbot Settings</span>
                    </Divider>

                    {/* Language  */}
                    <div className='createOwnBotForm-language'>
                        <p>Language</p>
                        <Dropdown
                            value={createOwnBotForm.language}
                            onChange={handleChange}
                            options={languagesFiltered()}
                            optionLabel="language"
                            filter
                            name='language'
                            placeholder="Select language"
                            className="w-full md:w-14rem"
                            style={{ width: '100%' }}
                        />
                    </div>


                    {/* Prompt Parameters or Description */}
                    <div className='createOwnBotForm-description'>
                        <p>Bot Introduction</p>
                        <InputTextarea
                            value={createOwnBotForm.description}
                            onChange={handleChange}
                            name='description'
                            rows={5}
                            cols={30}
                            style={{ width: '100%' }}
                            required
                        />
                    </div>

                    {/* Target Audience */}
                    <div className='createOwnBotForm-targetAudience'>
                        <p>Child-friendly</p>
                        <div style={{ width: "100%" }}>
                            <Checkbox
                                onChange={() => setTargetAudience(!targetAudience)}
                                checked={targetAudience}
                            />
                        </div>
                        {/* <Dropdown
                            value={createOwnBotForm.targetAudience}
                            onChange={handleChange}
                            options={targetAudiences}
                            optionLabel="target"
                            name="targetAudience"
                            placeholder="Select target audience"
                            className="w-full md:w-14rem"
                            style={{ width: '100%' }}
                        /> */}
                    </div>

                    <h4 style={{ marginBottom: '5%', marginTop: '5%' }}>Voice Settings</h4>
                    {/* Voice Settings Tone */}
                    {/* <div className='createOwnBotForm-tone'>
                        <div className='createOwnBotForm-tone-title'>
                            <p>Tone</p>
                        </div>
                        <div className='createOwnBotForm-tone-SliderContainer'>
                            <Slider 
                                value={voiceSettingTone}
                                onChange={ (e) => setVoiceSettingTone(e.value)}
                                step={10}
                                style={{marginBottom: '1rem', marginLeft:'1%'}}
                            />
                            <div style={{ display:'flex', justifyContent:'space-between'}}>
                                <label htmlFor="InitialNumber" style={{marginLeft:'0.5%'}} >0</label>
                                <label htmlFor="">100</label>
                            </div>
                        </div>
                    </div> */}
                    {/* Voice Settings speed */}
                    {/* <div className='createOwnBotForm-speed'>
                        <div className='createOwnBotForm-speed-title'>
                            <p>Speed</p>
                        </div>
                        <div className='createOwnBotForm-speed-SliderContainer'>
                            <Slider 
                                value={voiceSettingSpeed} 
                                onChange={(e) => setVoiceSettingSpeed(e.value)} 
                                step={10} 
                                style={{marginBottom: '1rem', marginLeft:'1%'}}
                            />
                            <div style={{ display:'flex', justifyContent:'space-between'}}>
                                <label htmlFor="">0</label>
                                <label htmlFor="">100</label>
                            </div>
                        </div>
                    </div> */}
                    {/* Voice Settings Volumen  */}
                    {/* <div className='createOwnBotForm-volumen'>
                        <div className='createOwnBotForm-volumen-title'>
                            <p>Volumen</p>
                        </div>
                        <div className='createOwnBotForm-volumen-SliderContainer'>
                            <Slider 
                                value={voiceSettingVolumen} 
                                onChange={(e) => setVoiceSettingVolumen(e.value)} 
                                step={10} 
                                style={{marginBottom: '1rem', marginLeft:'1%'}}
                            />
                            <div style={{ display:'flex', justifyContent:'space-between'}}>
                                <label htmlFor="">0</label>
                                <label htmlFor="">100</label>
                            </div>
                        </div>
                    </div> */}

                    <AudioPlayer
                        voicesProps={voices}
                        selectedLanguage={createOwnBotForm.language}
                        selectedVoices={selectedVoices}
                        setSelectedVoices={setSelectedVoices}
                        voiceSettingStability={voiceSettingStability}
                        setVoiceSettingStability={setVoiceSettingStability}
                        voiceSettingClarity={voiceSettingClarity}
                        setVoiceSettingClarity={setVoiceSettingClarity}
                        voiceSettingStyle={voiceSettingStyle}
                        setVoiceSettingStyle={setVoiceSettingStyle}
                        voiceSettingSpeakerBoost={voiceSettingSpeakerBoost}
                        setVoiceSettingSpeakerBoost={setVoiceSettingSpeakerBoost}
                        style={{ marginBottom: "1rem" }}
                    />


                    {/* submit */}
                    <div className='createOwnBotForm-submit'>
                        <Button label='Cancel' onClick={handleQuitDialog} ></Button>
                        <Button type='submit' loading={createLoading} className="p-button-success" label='Create'></Button>
                    </div>
                </form>
            </Dialog>
        </>
    )
}
