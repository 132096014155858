import React, { useState, } from 'react'
import { InputText } from 'primereact/inputtext'
import { Button } from 'primereact/button'
import { useNavigate } from 'react-router-dom'

import { resetPassword, confirmPassword } from './service.js'

import './styles.css'

export const RecoveryPassword = () => {
    const navigate = useNavigate()
    const [RPEmail, setRPEmail] = useState('')
    const [emailLoading, setEmailLoading] = useState(false)
    const [confirmCode, setConfirmCode] = useState(false)
    const [emailError, setEmailError] = useState('')

    // for receive Code
    const [confirmationCode, setConfirmationCode] = useState('')
    const [requestError, setRequestError] = useState('')
    const [codeLoading, setCodeLoading] = useState(false)

    // confirmPassword
    const [password, setPassword] = useState('')
    const [passwordConfig, setPasswordConfig] = useState('')

    const receiveCodeSubmit = async (e) => {
        e.preventDefault();
        try {
            setEmailLoading(true)
            const email = {
                "email": RPEmail
            }
            const response = await resetPassword(email)
            // console.log(response);
            setConfirmCode(true)
            setEmailLoading(false)
        } catch (error) {
            setEmailLoading(true)
            setEmailError(error.response.data.message)
            // console.log(error.response.data.message);
            setEmailLoading(false)
            // setConfirmCode(true) for testing 
        }
    }

    const sendCodeSubmit = async (e) => {
        e.preventDefault();
        try {
            setCodeLoading(true)
            if (password != null && password === passwordConfig) {
                // console.log('send Submit to email', RPEmail, confirmationCode, password);
                const form = {
                    "email": RPEmail,
                    "recoveryCode": confirmationCode,
                    "newPassword": password,
                }

                const response = await confirmPassword(form)
                navigate('/login')
            }
            setCodeLoading(false)
        } catch (error) {
            setCodeLoading(true)
            setRequestError(error.response.data.message)
            // console.log(error.response.data.message);
            setCodeLoading(false)
        }
    }

    const goBack = () => {
        navigate(-1);
    }

    return (
        <div className='recoveryPasswordContainer'>
            <div>
                <i className='pi pi-arrow-left recoveryPasswordContainer-backIcon' onClick={goBack}></i>
            </div>
            <h1>Reset Password</h1>
            {
                (!confirmCode)
                    ?
                    <form onSubmit={receiveCodeSubmit} className='sendCodeContainer' >
                        <label htmlFor="ResetPassword"> An email with code will be sent you </label>
                        <InputText type='email' placeholder='Email' value={RPEmail} onChange={(e) => setRPEmail(e.target.value)} required />
                        <div style={{ 'display': 'flex', 'justifyContent': 'center', }}>
                            <Button style={{ 'width': '40%' , 'display': 'flex', justifyContent: 'center' }} type='submit'  loading={emailLoading} >Send Code</Button>
                        </div>
                        <div style={{ 'display': 'flex', 'justifyContent': 'center' }}>
                            {
                                (emailError)
                                    ?
                                    <small className='p-error' >
                                        {emailError}
                                    </small>
                                    : null
                            }
                        </div>
                    </form>
                    :
                    <form onSubmit={sendCodeSubmit} className='sendCodeContainer'>
                        <label htmlFor="ResetPassword-confirmCode"> Confirm code <small > (Check your Inbox Email or span folder) </small></label>
                        <InputText placeholder='code' value={confirmationCode} onChange={(e) => setConfirmationCode(e.target.value)} />
                        <div className='recoveryPassword-password-input'>
                            <label htmlFor="ResetPassword-changePassword"> Change Password</label>
                            <InputText type='password' placeholder='Set a New Password' value={password} onChange={(e) => setPassword(e.target.value)} required />
                            <InputText type='password' placeholder='Confirm Password' value={passwordConfig} onChange={(e) => setPasswordConfig(e.target.value)} required />
                        </div>
                        <div className='recoveryPassword-password-warning'>
                            {
                                (password != null && password !== passwordConfig)
                                    ? <small className='p-error'> Password is not the same </small>
                                    :
                                    null
                            }
                        </div>
                        {
                            (password != null && password === passwordConfig)
                                ? <Button type='submit' loading={codeLoading} >Confirm</Button>
                                : <Button type='submit' loading={codeLoading} disabled >Confirm</Button>
                        }
                        <div style={{ 'display': 'flex', 'justifyContent': 'center' }}>
                            {
                                (requestError)
                                    ?
                                    <small className='p-error' >
                                        {requestError}
                                    </small>
                                    : null
                            }
                        </div>

                    </form>
            }
        </div>
    )
}
