import axios from "axios";
import { axiosProvider } from "../../axios/config/axios.config";

async function resetPassword(email) {
    const response = await axios.post('/auth/reset-password', email )
    return response.data;
};

async function confirmPassword(form){
    const response = await axios.post('/auth/confirm-password', form)
    return response.data 
}

export { resetPassword, confirmPassword }