import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

//theme, core ,icons
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
// import 'primeflex/primeflex.css';

// components 
import { Layout } from '../components/Layout'
import { Landing } from '../components/Landing';
import { Home } from '../components/Home';
import { Login } from '../components/Login';
import { Signup } from '../components/Signup';
import { Payment } from '../modules/Payment';
import { RecoveryPassword } from '../components/RecoveryPassword';
import { NotFound } from '../components/NotFound';

// auth 
import { AuthProvider } from '../hooks/useAuth';
import '../styles/global.css'

//context 
import { CurrentBalanceContextProvider } from '../context/CurrentBalanceContextProvider';


const App = () => {
    return (
        <BrowserRouter>
            <AuthProvider>
                <CurrentBalanceContextProvider> 
                    <Layout>
                        <Routes>
                            <Route path="/" element={<Landing />} />
                            <Route path="/home" element={<Home />} />
                            <Route path="/login" element={<Login />} />
                            <Route path='/signup' element={<Signup />} />
                            <Route path="/recovery-Password" element={<RecoveryPassword />} />
                            <Route path="/payment" element={<Payment />} />
                            <Route path='*' element={<NotFound />} />
                        </Routes>
                    </Layout>
                </CurrentBalanceContextProvider>
            </AuthProvider>
        </BrowserRouter>
    );
}

export default App;
