import React from 'react';
// import 'styles.css'

export const DetailOwnBot = ({ children, ownBot }) => {
    return (
        <div className='listOwnBot-body'>
            <div className='listOwnBot-body-name'>
                <p>Name</p>
                <p>{ownBot.name}</p>
            </div>
            <div className='listOwnBot-body-aiToken'>
                <p>API Key</p>
                {/* <p>{ownBot.aiToken}</p> */}
                <p>*****************</p>
            </div>
            <div className='listOwnBot-body-description'>
                <p>Prompt Parameters</p>
                <p>{ownBot.description}</p>
            </div>
            <div className='listOwnBot-body-footer'>
                {
                    children
                }
            </div>
        </div>
    )
}
