import axios from "axios";

const baseURL = process.env.REACT_APP_API_URL

axios.defaults.baseURL = baseURL;
axios.defaults.headers.common['Authorization'] = '';
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

const axiosProvider = axios.create({
    baseURL: baseURL,
    headers: {},
})

axiosProvider.interceptors.request.use(function (config) {
    const token = window.localStorage.getItem('token-ownchat');
    config.headers.Authorization = `bearer ${token}`;
    return config
})

axiosProvider.interceptors.response.use(
    (response) => {
        return response
    },
    (error) => validateResponse(error)
);

function validateResponse(error) {
    console.error(error);
    if (error.response.status == 404) {
      return;
    }
    if (error.response.status == 401) {
      window.location.href = '/login';
    }
    if (error.response) {
      throw {
        isAxiosError: true,
        code: error.response.status,
        message: error.response.data.message,
      };
    } else if (error.request) {
      if (error.message === 'Network Error') {
        throw { isAxiosError: true, code: 500, message: 'Connection Error' };
      }
    } else {
      console.error('Request Error', error.message);
    }
    throw { isAxiosError: true, code: 500, message: 'Server Error' };
  }

export { axiosProvider }