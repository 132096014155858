import React, { useState, useEffect } from 'react';
import { Howl, Howler } from 'howler'
import testAudio from '../../assets/TestAudio/80svibe.mp3';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { Slider } from 'primereact/slider';
import { Checkbox } from 'primereact/checkbox';

// services
import { obtainVoices, obtainAudio } from './services.js'

import './styles.css'

export const AudioPlayer = (
    {
        voicesProps,
        selectedLanguage,
        selectedVoices,
        setSelectedVoices,
        voiceSettingStability,
        setVoiceSettingStability,
        voiceSettingClarity,
        setVoiceSettingClarity,
        voiceSettingStyle,
        setVoiceSettingStyle,
        voiceSettingSpeakerBoost,
        setVoiceSettingSpeakerBoost

    }) => {
    const [voices, setVoices] = useState([]);
    // advancedVoiceSettings useState From parents
    // const [selectedVoices, setSelectedVoices] = useState()
    // const [voiceSettingStability, setVoiceSettingStability] = useState(0);
    // const [voiceSettingClarity, setVoiceSettingClarity] = useState(0);
    // const [voiceSettingStyle, setVoiceSettingStyle] = useState(0);
    // const [voiceSettingSpeakerBoost, setVoiceSettingSpeakerBoost] = useState(false);


    const [loadingAudio, setLoadingAudio] = useState(false)

    const [audio, setAudio] = useState()
    const [audioUrl, setAudioUrl] = useState(false)

    // Howler Sound state
    const [sound, setSound] = useState(false)

    // this const is how work with sound1  
    // const sound1 = new Howl({
    //     // src: ['https://storage.googleapis.com/eleven-public-prod/premade/voices/21m00Tcm4TlvDq8ikWAM/df6788f9-5c96-470d-8312-aab3b3d8f50a.mp3'],
    //     // https://api.elevenlabs.io/docs#/voices/Get_voices_v1_voices_get
    //     src: [testAudio],
    //     volume: 1,
    //     loop: false,
    // })

    useEffect(() => {
        const getVoices = () => {
            setVoices(voicesProps)
        }

        getVoices();
    }, [])

    // we are not using voiceslist preview 
    // useEffect(() => {
    //     if (selectedVoices) {
    //         const soundHowl = new Howl({
    //             src: [selectedVoices.preview_url],
    //             volume: 1,
    //             loop: false
    //         })
    //         setSound(soundHowl);

    //         return () => {
    //             soundHowl.unload(); // clean resources of audio when dismount 
    //             setSound(false);
    //         }
    //     }
    //     else {
    //         // console.log('no hay un selected Voice');
    //     }
    // }, [selectedVoices])

    useEffect(() => {
        if (audio) {
            try {
                // console.log('hay un audio');
                // console.log(audio);
                // url for htmlDom multimedia

                const previewAudio = new Blob([audio], { type: 'audio/mpeg' });
                // const previewAudio = new Blob([audio], { type: 'audio/mp3' });

                const url = URL.createObjectURL(previewAudio)
                // console.log(typeof url);
                // console.log(url);
                setAudioUrl(url)


                // for Howler
                const arrayBuffer = audio;
                const base64String = btoa(String.fromCharCode(... new Uint8Array(arrayBuffer)))

                // const contentType = "audio/mp3";
                // src: [`data:${contentType};base64,${base64Str}`],

                //convertiendo el arrayBuffer a base64
                // dissanvantage el forEach tiene que hacerse con mucho carga jodida del proceso 
                // const uint8Array = new Uint8Array(audio);
                // let binaryString = '';
                // uint8Array.forEach((byte) => {
                //     binaryString += String.fromCharCode(byte);
                // });
                // const base64String = btoa(binaryString);
                // // console.log(base64String);
                // src: [`data:audio/mp3;base64,${base64String}`],


                const soundHowl = new Howl({
                    src: [`data:audio/mp3;base64,${base64String}`],
                    volume: 1,
                    loop: false,
                })

                soundHowl.play()

                return () => {
                    soundHowl.unload();
                    URL.revokeObjectURL(url);
                    // setAudio(false); 
                }
            } catch (error) {
                console.log(error);
            }
        }
        else {
            // console.log('no hay un audio');
        }

    }, [audio])


   

    const testSettingVoice = async () => {
        setLoadingAudio(true)
        const body = {
            text: selectedLanguage.text,
            model_id: "eleven_multilingual_v2",
            voice_settings: {
                stability: voiceSettingStability / 100,
                similarity_boost: voiceSettingClarity / 100,
                style: voiceSettingStyle / 100,
                use_speaker_boost: voiceSettingSpeakerBoost
            }
        }
        const voiceId = selectedVoices.voice_id

        // console.log(body, voiceId);
        try {
            const response = await obtainAudio(voiceId, body);
            // console.log(response);
            setAudio(response)
            setLoadingAudio(false);
        } catch (error) {
            console.log(error);
            setLoadingAudio(false);
        }
    }

     // preview Voice is how howler Work
    const previewVoiceAudio = () => {
        if (!sound.playing()) {
            sound.play();
        }
    }
    const checkSound = () => {
        console.log(sound.playing());
    }
    const playTest = () => {
        console.log('playTest');
        console.log(sound);

        sound1.play();
    }
    const pausePlaytest = () => {
        console.log('PausePlayTest');
        console.log(sound);
        sound.pause();
    }
    const stopPlayTest = () => {
        console.log('StopPlayTest');

        sound.stop();
    }

    return (
        <div style={{ marginTop: '1rem' }}>
            {/*  Voices */}
            <div className='audioPlayer-Voice'>
                <div className='audioPlayer-Voice-section1'>
                    <span>Select Voice</span>
                </div>
                {
                    voices &&
                    <div className='audioPlayer-Voice-section2'>
                        <Dropdown
                            value={selectedVoices}
                            onChange={e => setSelectedVoices(e.value)}
                            options={voices}
                            optionLabel='name'
                            placeholder='Select an Voice'
                            className='w-full md:w-14rem'
                            required
                        />
                        {/* {
                            (selectedVoices)
                                ?
                                <>
                                    {
                                        !advancedSettingToggle &&
                                       
                                        <Button
                                            label='Preview Voice'
                                            onClick={previewVoiceAudio}
                                            icon="pi pi-play"
                                            iconPos='right'
                                            className="p-button-outlined"
                                        />
                                    }

                                </>

                                :
                                <Button
                                    label='Preview Voice'
                                    icon="pi pi-play"
                                    iconPos='right'
                                    disabled
                                    className="p-button-outlined"
                                />

                        } */}

                    </div>
                }
            </div>

            {/*Advanced Voice Setting Button  */}
            {/* <div className='audioPlayer-AdvancedSettings'>
                Advanced Voice Settings
            </div> */}
            {/* {
                selectedVoices &&
                <>
                    <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '1rem' }}>
                        <button onClick={previewVoiceAudio} >Preview voice Selected</button>
                        <button onClick={testSettingVoice}> Test Setting Voice </button>
                    </div>
                    <Button
                        type='button'
                        label='Advanced Voice Settings'
                        className="p-button-text"
                        icon="pi pi-angle-down"
                        iconPos='right'
                        style={{ marginBottom: '1rem' }}
                        onClick={() => setAdvancedSettingToggle(!advancedSettingToggle)}
                    />
                </>
            } */}
            {
                // advancedSettingToggle &&
                <>
                    {/* stability */}
                    <div className='audioPlayer-stability'>
                        <div className='audioPlayer-stability-title'>
                            <p>Stability</p>
                        </div>
                        <div className='audioPlayer-stability-SliderContainer'>
                            <Slider
                                value={voiceSettingStability}
                                onChange={(e) => setVoiceSettingStability(e.value)}
                                step={10}
                                style={{ marginBottom: '1rem', marginLeft: '1%' }}
                            />
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <span htmlFor="InitialNumber" style={{ marginLeft: '0.5%' }} >0</span>
                                <span htmlFor="">100</span>
                            </div>
                        </div>
                    </div>
                    {/* clarity */}
                    <div className='audioPlayer-clarity'>
                        <div className='audioPlayer-clarity-title'>
                            <p>Clarity</p>
                        </div>
                        <div className='audioPlayer-stability-SliderContainer'>
                            <Slider
                                value={voiceSettingClarity}
                                onChange={(e) => setVoiceSettingClarity(e.value)}
                                step={10}
                                style={{ marginBottom: '1rem', marginLeft: '1%' }}
                            />
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <span htmlFor="InitialNumber" style={{ marginLeft: '0.5%' }} >0</span>
                                <span htmlFor="">100</span>
                            </div>
                        </div>
                    </div>
                    {/* style */}
                    <div className='audioPlayer-style'>
                        <div className='audioPlayer-style-title'>
                            <p>Style</p>
                        </div>
                        <div className='audioPlayer-style-SliderContainer'>
                            <Slider
                                value={voiceSettingStyle}
                                onChange={(e) => setVoiceSettingStyle(e.value)}
                                step={10}
                                style={{ marginBottom: '1rem', marginLeft: '1%' }}
                            />
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <span htmlFor="InitialNumber" style={{ marginLeft: '0.5%' }} >0</span>
                                <span htmlFor="">100</span>
                            </div>
                        </div>
                    </div>
                    {/* speakerBoost */}
                    <div className='audioPlayer-speakerBoost'>
                        <div className='audioPlayer-speakerBoost-title'>
                            <p>Speaker Boost</p>
                        </div>
                        <div className='audioPlayer-speakerBoost-checkBoxContainer'>
                            <Checkbox onChange={() => setVoiceSettingSpeakerBoost(!voiceSettingSpeakerBoost)} checked={voiceSettingSpeakerBoost} />
                        </div>

                    </div>

                    {/* Button */}
                    <div className='audioPlayer-previewButton' >
                        <div className='audioPlayer-previewButton-Button'>
                            {
                                (selectedVoices && selectedLanguage)
                                    ? <Button
                                        type='button'
                                        loading={loadingAudio}
                                        label='Preview Voice'
                                        icon="pi pi-play"
                                        iconPos='right'
                                        className="p-button-outlined"
                                        onClick={testSettingVoice}
                                        style={{ width: '100%', marginBottom: '1rem' }}
                                    />
                                    : <Button
                                        type='button'
                                        loading={loadingAudio}
                                        label='Preview Voice'
                                        icon="pi pi-play"
                                        iconPos='right'
                                        className="p-button-outlined"
                                        style={{ width: '100%', marginBottom: '1rem' }}
                                        disabled
                                    />
                            }

                        </div>
                    </div>
                </>
            }

            {
                // audioUrl &&
                // <audio controls>
                //     <h1>{audioUrl}</h1>
                //     <source src={audioUrl} type="audio/mpeg" />
                // </audio>

                // <audio id="myAudio" controls>
                //     <source src="horse.ogg" type="audio/ogg">
                //     <source src="horse.mp3" type="audio/mpeg">
                //     Your browser does not support the audio element.
                // </audio>
            }
        </div>
    )
}
