import React, { useEffect, useState } from 'react';
import { Card } from 'primereact/card';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';

// react Icons
import { AiFillDollarCircle } from "react-icons/ai";

//components 
import { PaypalPayment } from '../PaypalPayment';

//styles
import './styles.css'

export const PaymentCard = ({ creditPack }) => {
    // CreditPack = {
    //     id,
    //     packName,
    //     creditAmount,
    //     packPrice
    // }

    const [paymentCardPurchaseVisible, setPaymentCardPurchaseVisible] = useState(false);

    useEffect(() => {
        // console.log(creditPack);
    }, [])

    // PaymentCard localComponent
    const PaymentCardTitle = (
        <h1 style={{
            // fontSize: '2rem',
            fontSize: '100%',
            display: 'flex',
            justifyContent: 'flexStart',
            alignItems: 'center'
        }}>
            {"$" + creditPack.packPrice + " USD  x " + creditPack.creditAmount + "  "}
            <AiFillDollarCircle
                style={{
                    marginLeft: '1%',
                    color: '#6366F1',
                }}
                size={'1.5rem'}
            // size={'10%'}
            />
        </h1>
    );

    const PaymentCardSubTitle = (
        <p
            style={{
                fontWeight: 'bold',
                color: '#6366F1',
            }}
        >
            {creditPack.packName}
        </p>
    )

    const PaymentFooterButton = (
        <footer style={{
            position: 'absolute',
            bottom: '1.25rem',
            left: '-1.25rem',
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end'
        }} >
            <main style={{
                backgroundColor: 'Gray',
                width: '75%',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
            }}
            >
                <label
                    style={{
                        width: '45%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        fontWeight: 'bold',
                        color: 'white',
                    }}
                >
                    {"$ " + creditPack.packPrice + " USD"}
                </label>
                <Button
                    style={{ width: '65%' }}
                    label={`Add ${creditPack.creditAmount} Credits`}
                    onClick={() => setPaymentCardPurchaseVisible(true)}
                />
            </main>
        </footer>
    )

    //PaymentCard Methods

    const handleQuitDialog = () => {
        setPaymentCardPurchaseVisible(false);
    }


    return (
        <>
            <Card
                title={PaymentCardTitle}
                subTitle={PaymentCardSubTitle}
                // footer={PaymentFooterButton}
                className='PaymentCard-card'
            >
                <p className="m-0">
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit. Inventore sed consequuntur error repudiandae
                    numquam deserunt quisquam repellat libero asperiores earum nam nobis, culpa ratione quam perferendis esse, cupiditate neque quas!
                </p>

                {PaymentFooterButton}
            </Card>

            {/* Purchase Credits Dialog */}
            <Dialog
                header="Purchase Credits"
                visible={paymentCardPurchaseVisible}
                onHide={handleQuitDialog}
                className='PaymentCard-Dialog'
            >
                <main
                    className='PaymentCard-Dialog-container'
                >
                    {/* purchase {creditPack.creditAmount} credits */}
                    {/* Details */}
                    <section
                        className='PaymentCard-Dialog-DetailSection'
                    >
                        {/* Align Detail paragraph */}
                        <div
                            className='PaymentCard-Dialog-DetailSection-Detail'
                        >
                            <p
                                style={{ textAlign: 'justify' }}
                            >
                                Puchase
                                <strong style={{ color: '#6366F1' }}>
                                    {" " + creditPack.packName}
                                </strong>
                                for
                                <strong style={{
                                    color: '#6366F1',
                                }}>
                                    {" " + creditPack.creditAmount + " "}
                                </strong>
                                credits
                            </p>
                        </div>
                        {/* Align Detail currency */}

                        <div
                            className='PaymentCard-Dialog-DetailSection-Detail'
                        >
                            <h2>
                                {"$ " + creditPack.packPrice + " USD"}
                            </h2>
                        </div>
                    </section>
                    {/* Billing */}
                    <section
                        className='PaymentCard-Dialog-BillingSection'
                    >
                        <PaypalPayment creditPack={creditPack} handleQuitDialog={handleQuitDialog} />
                    </section>
                </main>
            </Dialog>
        </>
    )
}
