import axios from "axios";
import { axiosProvider } from "../../axios/config/axios.config";


async function obtainPaymentPacks() {
    const response = await axiosProvider.get('/CreditTransaction/transaction/credits/packs/all');
    return response.data
}

async function purchaseCredits(purchaseApprove) {
    const response = await axiosProvider.post('/CreditTransaction/transaction/credits/purchase', purchaseApprove);
    return response.data
}

async function obtainCurrentBalance(userId) {
    const response = await axiosProvider.get(`/CreditTransaction/transaction/credits/currentBalance/${userId}`);
    return response.data
}

export {
    obtainPaymentPacks,
    purchaseCredits,
    obtainCurrentBalance
}
