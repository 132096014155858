import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';

// services 
import { signup } from './service';

// styles
import './styles.css'

export const Signup = () => {
    const [form, setForm] = useState({
        email: '',
        passwordHash: '',
        phoneNumber: '',
        username: '',
        firstName: '',
    })
    const [loading, setLoading] = useState(false)
    const [passwordConfig, setPasswordConfig] = useState('')
    const [error, setError] = useState('')

    const navigate = useNavigate();

    const handleSubmit = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        })
    }
    const onClickFormButton = async (e) => {
        e.preventDefault();
        setLoading(true)
        try {
            if( form.passwordHash === passwordConfig ){
                const response = await signup(form)
            }
            setLoading(false)
            navigate('/login')
        } catch (error) {
            setError(error.response.data.message)
            setLoading(false)
        }
    }
    const goBack = () => {
        navigate(-1);
    }

    return (
        <div className='signupForm'>
            <div>
                <i className='pi pi-arrow-left signupForm-backIcon' onClick={goBack} ></i>
            </div>
            <h1>Signup</h1>
            <form onSubmit={onClickFormButton}>
                <div className='signup-email'>
                    <InputText type='email' name='email' placeholder="Email" value={form.email} onChange={handleSubmit} required />
                </div>
                <div className='signup-password'>
                    <div className='signup-password-input'>
                        <InputText name='passwordHash' type="password" placeholder="Set a Password" value={form.passwordHash} onChange={handleSubmit} required />
                        <InputText name='confirmPassword' type="password" placeholder="Confirm Password" value={passwordConfig} onChange={(event) => setPasswordConfig(event.target.value)} required />
                    </div>
                    <div className='signup-password-warning'>
                        {
                            (form.passwordHash != null && form.passwordHash !== passwordConfig )
                            ?
                                <small className='p-error' >
                                    Password is not same 
                                </small>
                            :
                                null
                        }
                    </div>
                </div>
                <div className='signup-phone'>
                    <InputText name='phoneNumber' placeholder='PhoneNumber' value={form.phoneNumber} onChange={handleSubmit} required />
                </div>
                <div className='signup-username'>
                    <InputText name='username' placeholder='Username' value={form.username} onChange={handleSubmit} required />
                </div>
                <div className='signup-firstName'>
                    <InputText name='firstName' placeholder='Firstname' value={form.firstName} onChange={handleSubmit} required />
                </div>
                {/* <Button className='FormButton' label="Signup" onClick={onClickFormButton} loading={loading} disabled={true}/> */}
                <Button type='submit' className='FormButton' label="Signup" loading={loading} />
            </form>
            {
                (error)
                ? <small className='p-error' >
                    {error}
                  </small>
                : null
            }
        </div>
    )
}
