 import axios from "axios";
import { axiosProvider } from "../../axios/config/axios.config";

async function obtainChatBotOptions() {
    const response = await axiosProvider.get('/ChatBot');
    return response.data
}

async function obtainOwnBots(userId) {
    const response = await axiosProvider.get('/User/userChatbot');
    // const response = await axiosProvider.get(`/User/${userId}/userChatBot`);
    return response.data;
}

async function createOwnBots(form) {
    const user = JSON.parse(localStorage.getItem("userOwnChat"))
    const formData =
    {
        "aspNetUserId": user.id,
        "name": form.name,
        "chatBotId": form.chatbot,
        "aiToken": form.aiToken,
        "description": form.description,
        "language":form.language,
        "targetAudience": form.targetAudience,
        // voiceSettings
        "tone":form.tone,
        "speed":form.speed,
        "volumen":form.volumen,

        "stability": form.stability,
        "similarityBoost": form.similarityBoost,
        "style": form.style,
        "useSpeakerBoost": form.useSpeakerBoost,
        "voiceId": form.voiceId

    }
    const response = await axiosProvider.post('/User/userChatbot', formData);
    return response.data
}

async function editOwnBots(form) {
    const userChatBotId = form.userChatBotId;
    delete form.userChatBotId;
    delete form.ChatBot

    const response = await axiosProvider.put(`/User/userChatbot/${userChatBotId}`, form );
    return response.data;
}

async function deleteOwnBots (userChatBotId) {
    // console.log(userChatBotId);
    const response = await axiosProvider.delete(`/User/userChatbot/${userChatBotId}`)
    return response.data 
}

export {
    obtainChatBotOptions,
    obtainOwnBots,
    createOwnBots,
    editOwnBots,
    deleteOwnBots,
}