import React, { useState, useEffect } from 'react'
import { InputText } from 'primereact/inputtext'
import { Button } from 'primereact/button'
import { Link } from "react-router-dom";
import { useAuth } from '../../hooks/useAuth';
import { login } from './service';


import './styles.css'

export const Login = () => {
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [loadingLogin, setLoadingLogin] = useState(false);
  const [wrongUser, setWrongUser] = useState(false);


  // auth
  const auth = useAuth();

  const onClickFormButton = async () => {
    try {
      setLoadingLogin(true)
      const user = {
        "email": userName,
        "password": password
      }
      const response = await login(user)
      await auth.login({ userName: response.user, jwt: response.token })
      setLoadingLogin(false)

    } catch (error) {
      setLoadingLogin(false)
      setWrongUser(true)
    }
  }

  // for submit  change later 
  // const loginButton = (e) => {
  //   e.preventDefault();
  // }

  return (
    <div className='loginForm'>
      <h1>Login</h1>
      <div className='loginForm-userName'>
        <InputText className="loginForm-userName-text" type="text" value={userName} onChange={(e) => setUserName(e.target.value)} placeholder={"Email"} />
      </div>
      <div className='loginForm-password'>
        <InputText type="password" className="loginForm-password-text" value={password} onChange={(e) => setPassword(e.target.value)} placeholder={"Password"} />
      </div>
      <div className='loginForm-button'>
        <Button className='loginForm-formButton' onClick={onClickFormButton} loading={loadingLogin} label="Login" />
      </div>
      <div className='loginForm-password-recovery'>
        <small id="password-help">
          {/* <Link>Forgot your Password?</Link> */}
          <Link to={'/recovery-password'}>Forgot your <strong className='loginForm-link-password'>password?</strong> </Link>
        </small>
      </div>
      <div className='loginForm-signup'>
        <small>
          <Link to={'/signup'}>Create an account</Link>
        </small>
      </div>
      <div className='loginForm-wrongUser'>
        {
          (wrongUser)
            ?
            <small id="wrong-user">
              Wrong User or Password
            </small>
            : null
        }
      </div>

    </div>
  )
}
