import React, { useState, useEffect } from 'react';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';

import './styles.css'

// services
import { editOwnBots, deleteOwnBots } from '../../../../service.js';

export const EditOwnBot = ({ ownBot, handleActiveEdit, handleScrollOnClick, index, editOwnBotsState, deleteOwnBotsState }) => {
    const [editOwnBotForm, setEditOwnBotForm] = useState(
        {
            id: '',
            name: '',
            aiToken: '',
            description: '',
        }
    )
    const [editLoading, setEditLoading] = useState(false);

    // aiToken
    const [aiTokenVisible, setAiTokenVisible] = useState(false);
    const [aiTokenForm, setAiTokenForm] = useState("");
    const [aiToken, setAiToken] = useState(null);

    // deleteOwnBot
    const [deleteOwnBotVisible, setDeleteOwnBotVisible] = useState(false);
    const [deleteLoading, setDeleteLoading] = useState(false);


    useEffect(() => {
        // console.log(ownBot);
        // setEditOwnBotForm()

        getEditOwnBot(ownBot);
    }, [])

    const getEditOwnBot = (ownBot) => {
        const element = {
            aiToken: ownBot.aiToken,
            aspNetUserId: ownBot.aspNetUserId,
            chatBot: ownBot.chatBot,
            chatBotId: ownBot.chatBotId,
            dateCreated: ownBot.dateCreated,
            dateUpdated: ownBot.dateUpdated,
            description: ownBot.description,
            name: ownBot.name,
            userChatBotId: ownBot.userChatBotId
        }
        setEditOwnBotForm(element);
    }
    // change OwnBotForm
    const handleChange = event => {
        const { name, value } = event.target;
        setEditOwnBotForm(prevFormData => ({
            ...prevFormData,
            [name]: value
        }))
    }

    // open Modal 
    const handleEditAiToken = () => {
        setAiTokenVisible(true);
    }

    const handleChangeAiToken = (e) => {
        // console.log(e.target.value);
        setAiTokenForm(e.target.value);
    }

    const submitChangeAiToken = (e) => {
        e.preventDefault();
        setAiToken(aiTokenForm)
        setAiTokenForm("");
        setAiTokenVisible(false);
    }

    const handleEditForm = async () => {
        try {
            setEditLoading(true);
            let form = {};
            if (aiToken) {
                form = {
                    aiToken: aiToken,
                    aspNetUserId: editOwnBotForm.aspNetUserId,
                    chatBotId: editOwnBotForm.chatBotId,
                    description: editOwnBotForm.description,
                    name: editOwnBotForm.name,
                    userChatBotId: editOwnBotForm.userChatBotId,
                }
            }
            else {
                form = {
                    aiToken: editOwnBotForm.aiToken,
                    aspNetUserId: editOwnBotForm.aspNetUserId,
                    chatBotId: editOwnBotForm.chatBotId,
                    description: editOwnBotForm.description,
                    name: editOwnBotForm.name,
                    userChatBotId: editOwnBotForm.userChatBotId,
                }
            }

            const response = await editOwnBots(form)   // service
            editOwnBotsState(response.data) //function from grandParent 
            setEditLoading(false);
            handleActiveEdit(index);

        } catch (error) {
            console.error(error);
        }
    }

    // delete OwnBot 
    const handleDeleteOwnBot = async (ownBotId) => {
        try {
            setDeleteLoading(true);
            deleteOwnBotsState(ownBotId) //function from grandParent
            await deleteOwnBots(ownBotId) //service
            setDeleteLoading(false); // loading False 
            setDeleteOwnBotVisible(false); // close DeleteDialog
            handleActiveEdit(index); // close activeEdit 
            handleScrollOnClick(index); // close handleScrollOnClick
        } catch (error) {
            console.error(error);
        }
    }

    return (
        <div className='listOwnBot-body'>
            <div className='listOwnBot-body-name'>
                <p>Name</p>
                <InputText
                    type="text"
                    value={editOwnBotForm.name}
                    onChange={handleChange}
                    name='name'
                    className="p-inputtext-sm"
                    style={{ width: '100%' }}
                    required
                />
            </div>
            <div className='listOwnBot-body-aiToken'>
                <p>API Key</p>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    {/* <p style={{ marginRight: '1rem' }}>{ownBot.aiToken}</p> */}
                    {
                        (!aiToken)
                            ?
                            <p style={{ marginRight: '1rem' }}>*****************</p>
                            :
                            <p style={{ marginRight: '1rem' }}>{aiToken}</p>
                    }
                    <small style={{ color: 'blue' }} onClick={handleEditAiToken}>Edit API Key</small>
                </div>
                {/* todo edit AiToken functionality */}
            </div>
            <div className='listOwnBot-body-description'>
                <p>Prompt Parameters</p>
                <InputTextarea
                    value={editOwnBotForm.description}
                    onChange={handleChange}
                    name='description'
                    rows={5}
                    cols={30}
                    style={{ width: '100%' }}
                    required
                />
            </div>
            <div className='editOwnBot-body-deleteContainer'>
                <header style={{ borderBottom: '1px solid red', paddingBottom: '1rem', marginBottom: '1rem' }}>
                    {/* <p>Danger Zone</p> */}
                </header>
                <div className='editOwnBot-body-delete' >
                    <p>Delete <strong>{ownBot.name}</strong> </p>
                    <Button label='Delete this OwnBot' className="p-button-sm p-button-outlined p-button-danger" onClick={() => setDeleteOwnBotVisible(true)} ></Button>
                </div>
            </div>
            <div className='listOwnBot-body-footer'>
                <Button label='Cancel' onClick={() => handleActiveEdit(index)}></Button>
                <Button label='Save' className="p-button-success" onClick={() => handleEditForm()} loading={editLoading}></Button>
            </div>
            {/* Edit AiToken */}
            <Dialog header="Change API Key" visible={aiTokenVisible} style={{ width: '50vw' }} onHide={() => setAiTokenVisible(false)}>
                <form onSubmit={submitChangeAiToken}>
                    <InputText
                        type="text"
                        value={aiTokenForm}
                        onChange={handleChangeAiToken}
                        name="AiToken"
                        className='p-inputtext-sm'
                        style={{ width: '100%', marginBottom: '1rem' }}
                        required
                    />
                    <div className='listOwnBot-body-footer'>
                        <Button label='Cancel' onClick={() => setAiTokenVisible(false)}></Button>
                        <Button type='submit' className="p-button-success" label='Change API Key' ></Button>
                    </div>
                </form>

            </Dialog>

            {/* Delete Ownbot  */}
            <Dialog
                header="Delete OwnBot"
                visible={deleteOwnBotVisible}
                style={{ width: '50vw' }}
                onHide={() => setDeleteOwnBotVisible(false)}
            >
                <div>
                    <p>Are you sure you want to delete <strong> {ownBot.name}</strong>?</p>
                </div>
                <div className='listOwnBot-body-footer'>
                    <Button label='Cancel' onClick={() => setDeleteOwnBotVisible(false)}></Button>
                    <Button label='Delete this OwnBot' className="p-button-danger" onClick={() => handleDeleteOwnBot(ownBot.userChatBotId)} loading={deleteLoading}></Button>
                </div>
            </Dialog>
        </div>
    )
}