import React, { useState, useEffect } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Divider } from 'primereact/divider';
import { Dropdown } from 'primereact/dropdown';
import { Checkbox } from 'primereact/checkbox';
import { Slider } from 'primereact/slider';
import { InputTextarea } from 'primereact/inputtextarea';
import { Tag } from 'primereact/tag';
import { Dialog } from 'primereact/dialog';

// data
import { languages } from '../../../../../../shared/languages';
import { targetAudiences } from '../../../../../../shared/targetAudience';
import { deleteOwnBots, editOwnBots } from '../../../../service';

// Components
import { AudioPlayer } from '../../../../../../components/AudioPlayer/AudioPlayer';

// styles we are using the styles of createOwnBotForm 


export const EditOwnBotForm = ({ ownBot, voicesProps, handleQuitDialog, editOwnBotsState, deleteOwnBotsState, chatBotOptions }) => {
    const [ownBotForm, setOwnBotForm] = useState({
        userChatBotId: '',
        aspNetUserId: '',
        name: '',
        chatBot: '',
        chatBotId: '',
        dateCreated: '',
        dateUpdated: '',
        aiToken: '',
        description: '',
        language: '',
        targetAudience: '',
    });

    const [selectedChatBotOption, setSelectedChatBotOption] = useState(ownBot.chatBot) //selectedChatbotOption depends if ownbot can change Ai 

    const [targetAudience, setTargetAudience] = useState(false);

    // voiceSetting Scroll
    const [voiceSettingTone, setVoiceSettingTone] = useState(0)
    const [voiceSettingSpeed, setVoiceSettingSpeed] = useState(0)
    const [voiceSettingVolumen, setVoiceSettingVolumen] = useState(0)

    const [selectedVoices, setSelectedVoices] = useState()
    const [voiceSettingStability, setVoiceSettingStability] = useState(0);
    const [voiceSettingClarity, setVoiceSettingClarity] = useState(0);
    const [voiceSettingStyle, setVoiceSettingStyle] = useState(0);
    const [voiceSettingSpeakerBoost, setVoiceSettingSpeakerBoost] = useState(false);

    // loadings
    const [editLoading, setEditLoading] = useState(false)
    const [deleteLoading, setDeleteLoading] = useState(false)

    const [deleteOwnBotVisible, setDeleteOwnBotVisible] = useState(false)

    useEffect(() => {
        getEditOwnBot(ownBot);
    }, [])

    // Methods

    // Get OwnBot Props to state 
    // To Do change voiceSettings
    // remove Tone, Speed , Volumen, 
    // add stability, clarity , style , speakerBoost, voiceId


    const languagesFiltered = () => {
        const laguagesFilter =  languages.sort((a,b)=> {
            const languageA = a.language.toUpperCase();
            const languageB = b.language.toUpperCase(); 

            if(languageA < languageB){
                return -1
            }
            if(languageA > languageB){
                return 1; 
            }
            return 0
        })

        return laguagesFilter;
    }


    const getEditOwnBot = (ownBot) => {
        const languageFiltered = languages.find(language => language.language === ownBot.language)
        // const targetAudienceFiltered = targetAudiences.find(targetAudience => targetAudience.target === ownBot.targetAudience)

        const chatbotSelected = chatBotOptions.find(chatBot => chatBot.name === ownBot.chatBot.name);
        setSelectedChatBotOption(chatbotSelected)

        const element = {
            aiToken: ownBot.aiToken,
            aspNetUserId: ownBot.aspNetUserId,
            chatBot: ownBot.chatBot,
            chatBotId: ownBot.chatBotId,
            dateCreated: ownBot.dateCreated,
            dateUpdated: ownBot.dateUpdated,
            description: ownBot.description,
            name: ownBot.name,
            userChatBotId: ownBot.userChatBotId,
            language: languageFiltered,
            // targetAudience: targetAudienceFiltered,
        }
        // targetAudience will change to boolean fix later
        if(ownBot.targetAudience === 'minor'){
            setTargetAudience(true);
        }

        setOwnBotForm(element);
        // To Do change voiceSettings
        setVoiceSettingTone(ownBot.tone);
        setVoiceSettingSpeed(ownBot.speed);
        setVoiceSettingVolumen(ownBot.volumen);

        // voiceSetting
        // console.log(voicesProps);
        const selectedVoicesFilteres = voicesProps.filter(voiceProp => voiceProp.voice_id === ownBot.voiceId); 
        setSelectedVoices(selectedVoicesFilteres[0]);
        setVoiceSettingStability(ownBot.stability * 100);  
        setVoiceSettingClarity(ownBot.similarityBoost * 100);
        setVoiceSettingStyle(ownBot.style * 100);
        setVoiceSettingSpeakerBoost(ownBot.useSpeakerBoost);

    }
    // handleChange Method
    const handleChange = event => {
        const { name, value } = event.target;
        setOwnBotForm(prevFormData => ({
            ...prevFormData,
            [name]: value
        }))
    }

    // Edit OwnBot
    const handleEditOwnBot = async (e) => {
        e.preventDefault();
        const targetAudienceConvertstring = (targetAudience) ? 'minor' :'adult' 
        try {
            setEditLoading(true)
            const form = {
                aiToken: ownBotForm.aiToken,
                aspNetUserId: ownBotForm.aspNetUserId,
                // chatBotId: ownBotForm.chatBotId,
                chatBotId: selectedChatBotOption.chatBotId,
                description: ownBotForm.description,
                name: ownBotForm.name,
                userChatBotId: ownBotForm.userChatBotId,
                language: ownBotForm.language.language,
                // changetargetAudience to bool
                targetAudience: targetAudienceConvertstring,
                // To Do change voiceSettings
                tone: voiceSettingTone,
                speed: voiceSettingSpeed,
                volumen: voiceSettingVolumen,

                // VoiceSettings
                stability: voiceSettingStability / 100, 
                similarityBoost: voiceSettingClarity / 100,
                style: voiceSettingStyle / 100,
                useSpeakerBoost : voiceSettingSpeakerBoost,
                voiceId : selectedVoices.voice_id,

            }
            // console.log(form);
            const response = await editOwnBots(form); //service
            editOwnBotsState(response.data); //function from grandParent 
            setEditLoading(false)
            handleQuitDialog();
        } catch (error) {
            console.log(error);
            setEditLoading(false)
        }
    }

    // Delete Ownbot
    const handleDeleteOwnBot = async (ownBotId) => {
        try {
            // console.log(ownBotId);
            setDeleteLoading(true);
            deleteOwnBotsState(ownBotId);
            await deleteOwnBots(ownBotId);
            setDeleteLoading(false);
            setDeleteOwnBotVisible(false);
            handleQuitDialog();
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <form onSubmit={handleEditOwnBot} className='createOwnBotForm'>
            {/* name */}
            <div className='createOwnBotForm-name'>
                <p>Name</p>
                <InputText
                    type='text'
                    value={ownBotForm.name}
                    onChange={handleChange}
                    name='name'
                    className='p-inputtext-sm'
                    style={{ width: '100%' }}
                    required
                />
            </div>

            <Divider align="left">
                <span className="p-tag">AI Settings</span>
            </Divider>

            {/*  ai */}
            <div className='createOwnBotForm-ai' >
                <p>AI</p>
                <Dropdown
                    value={selectedChatBotOption}
                    onChange={(e) => setSelectedChatBotOption(e.value)}
                    options={chatBotOptions}
                    optionLabel="name"
                    placeholder="Select an AI"
                    className="w-full md:w-14rem"
                    style={{ width: '100%' }}
                />
            </div>

            {/* aiToken */}
            <div className='createOwnBotForm-aiToken'>
                <p>API Key</p>
                <InputText
                    type='password'
                    value={ownBotForm.aiToken}
                    onChange={handleChange}
                    name='aiToken'
                    className='p-inputtext-sm'
                    style={{ width: '100%' }}
                    required
                />
            </div>

            <Divider align="left">
                <span className="p-tag">Ownbot Settings</span>
            </Divider>

            {/* language */}
            <div className='createOwnBotForm-language'>
                <p>Language</p>
                <Dropdown
                    value={ownBotForm.language}
                    onChange={handleChange}
                    options={languagesFiltered()}
                    optionLabel='language'
                    filter
                    name='language'
                    placeholder='Select Language'
                    className='w-full md:w-14rem'
                    style={{ width: '100%' }}
                />
            </div>

            {/* Prompt Parameters or Description */}
            <div className='createOwnBotForm-description'>
                <p>Bot Introduction</p>
                <InputTextarea
                    value={ownBotForm.description}
                    onChange={handleChange}
                    name='description'
                    rows={5}
                    cols={30}
                    style={{ width: '100%' }}
                    required
                />
            </div>

            {/* Target Audience  */}
            <div className='createOwnBotForm-targetAudience'>
                <p>Child-friendly</p>
                <div style={{width: "100%"}}>
                    <Checkbox  
                        onChange={()=> setTargetAudience(!targetAudience)}
                        checked={targetAudience}
                    />
                </div>
                {/* <Dropdown
                    value={ownBotForm.targetAudience}
                    onChange={handleChange}
                    options={targetAudiences}
                    optionLabel='target'
                    name='targetAudience'
                    placeholder='Select Target Audience'
                    className='w-full md:w-14rem'
                    style={{ width: '100%' }}
                /> */}
            </div>


            <h4 style={{ marginBottom: '2.5%', marginTop: '2.5%' }}>Voice Settings</h4>

            {/* Voice Settings Tone */}
            {/* <div className='createOwnBotForm-tone'>
                <div className='createOwnBotForm-tone-title'>
                    <p>Tone</p>
                </div>
                <div className='createOwnBotForm-tone-SliderContainer'>
                    <Slider
                        value={voiceSettingTone}
                        onChange={(e) => setVoiceSettingTone(e.value)}
                        step={10}
                        style={{ marginBottom: '1rem', marginLeft: '1%' }}
                    />
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <label htmlFor="InitialNumber" style={{ marginLeft: '0.5%' }} >0</label>
                        <label htmlFor="">100</label>
                    </div>
                </div>
            </div> */}
            {/* Voice Settings speed */}
            {/* <div className='createOwnBotForm-speed'>
                <div className='createOwnBotForm-speed-title'>
                    <p>Speed</p>
                </div>
                <div className='createOwnBotForm-speed-SliderContainer'>
                    <Slider
                        value={voiceSettingSpeed}
                        onChange={(e) => setVoiceSettingSpeed(e.value)}
                        step={10}
                        style={{ marginBottom: '1rem', marginLeft: '1%' }}
                    />
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <label htmlFor="">0</label>
                        <label htmlFor="">100</label>
                    </div>
                </div>
            </div> */}
            {/* Voice Settings Volumen  */}
            {/* <div className='createOwnBotForm-volumen'>
                <div className='createOwnBotForm-volumen-title'>
                    <p>Volumen</p>
                </div>
                <div className='createOwnBotForm-volumen-SliderContainer'>
                    <Slider
                        value={voiceSettingVolumen}
                        onChange={(e) => setVoiceSettingVolumen(e.value)}
                        step={10}
                        style={{ marginBottom: '1rem', marginLeft: '1%' }}
                    />
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <label htmlFor="">0</label>
                        <label htmlFor="">100</label>
                    </div>
                </div>
            </div> */}

            <AudioPlayer
                voicesProps={voicesProps}
                selectedLanguage={ownBotForm.language}
                selectedVoices={selectedVoices}
                setSelectedVoices={setSelectedVoices}
                voiceSettingStability={voiceSettingStability}
                setVoiceSettingStability={setVoiceSettingStability}
                voiceSettingClarity={voiceSettingClarity}
                setVoiceSettingClarity={setVoiceSettingClarity}
                voiceSettingStyle={voiceSettingStyle}
                setVoiceSettingStyle={setVoiceSettingStyle}
                voiceSettingSpeakerBoost={voiceSettingSpeakerBoost}
                setVoiceSettingSpeakerBoost={setVoiceSettingSpeakerBoost}
                style={{ marginBottom: "1rem" }}
            />

            <Divider align="left">
                <Tag severity="danger" value="Delete OwnBot"></Tag>
            </Divider>

            {/* Delete Ownbot  */}
            <Button 
                type='button' 
                label='Delete this OwnBot' 
                className="p-button-sm p-button-outlined p-button-danger" 
                onClick={() => setDeleteOwnBotVisible(true)}   
                style={{marginBottom:'1rem'}}
            />

            {/* submit */}
            <div className='createOwnBotForm-submit'>
                <Button label='Cancel' onClick={handleQuitDialog} ></Button>
                {/* <Button type='submit' loading={createLoading} className="p-button-success" label='Create'></Button> */}
                <Button type='submit' loading={editLoading} className="p-button-success" label='Edit'></Button>
            </div>

            {/* Delete OwnBotDialog */}
            <Dialog
                header="Delete OwnBot"
                visible={deleteOwnBotVisible}
                style={{ width: '50vw' }}
                onHide={() => setDeleteOwnBotVisible(false)}
            >
                <div style={{marginBottom:'1rem'}}>
                    <p>Are you sure you want to delete <strong> {ownBot.name}</strong>?</p>
                </div>
                <div className='listOwnBot-body-footer'>
                    <Button label='Cancel' onClick={() => setDeleteOwnBotVisible(false)}></Button>
                    <Button label='Delete this OwnBot' className="p-button-danger" onClick={() => handleDeleteOwnBot(ownBot.userChatBotId)} loading={deleteLoading}></Button>
                </div>

            </Dialog>
        </form>
    )
}
