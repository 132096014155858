import React, { useState } from 'react';
import { Button } from 'primereact/button';
import { InputTextarea } from 'primereact/inputtextarea';
import { InputText } from 'primereact/inputtext';
import { Dialog } from 'primereact/dialog';

// components
import { DetailOwnBot } from './components/DetailOwnBot/DetailOwnBot';
import { EditOwnBot } from './components/EditOwnBot/EditOwnBot';
import { EditOwnBotForm } from './components/EditOwnBotForm/EditOwnBotForm';

import './styles.css';

export const ListOwnBot = ({ ownBots, voicesProps , editOwnBotsState, deleteOwnBotsState, chatBotOptions }) => {
    // accordionTest
    const [activeIndex, setActiveIndex] = useState([]);
    const [activeEdit, setActiveEdit] = useState([]);

    // DialogVisibleState
    const [visible, setVisible] = useState(false);
    // openEditDialog OwnbotData
    const [ownBotData, setOwnBotData] = useState()

    const handleScrollOnClick = (itemIndex) => {
        let _activeIndex = activeIndex ? [...activeIndex] : [];

        if (_activeIndex.length === 0) {
            _activeIndex.push(itemIndex);
        }
        else {
            const index = _activeIndex.indexOf(itemIndex);

            if (index === -1)
                _activeIndex.push(itemIndex);
            else
                _activeIndex.splice(index, 1);
        }
        setActiveIndex(_activeIndex);
    }
    const copyClipBoard = (token) => {
        const textToCopy = token;

        // create element Temporal input
        const tempInput = document.createElement('textarea');
        tempInput.value = textToCopy;
        document.body.appendChild(tempInput);

        // select Temporal input 
        tempInput.select();
        tempInput.setSelectionRange(0, 99999); // for mobiles 

        // copy to Clipboard 
        document.execCommand('copy');

        // Remove temporal input
        document.body.removeChild(tempInput);

        // Optional: show alert! 
        alert('¡Token copied to Clipboard!');
    }
    // Edit Functions scroll EditFunctionsLogic
    const handleActiveEdit = (itemIndex) => {
        let _activeIndex = activeEdit ? [...activeEdit] : [];
        if (_activeIndex.length === 0) {
            _activeIndex.push(itemIndex);
        }
        else {
            const index = _activeIndex.indexOf(itemIndex);

            if (index === -1)
                _activeIndex.push(itemIndex);
            else
                _activeIndex.splice(index, 1);
        }
        setActiveEdit(_activeIndex);
    }

    // DialogEdit
    const handleDialogEdit = (ownBot) => {
        setOwnBotData(ownBot);
        setVisible(true);
    }

    //  Todo CleanForm
    const handleCleanForm = () => {
        // console.log('cleanForm' );
        setOwnBotData();
    }

    const handleQuitDialog = () => {
        handleCleanForm();
        setVisible(false);
    }


    // ToDo we are not using EditOwnBot component for the refactor 

    return (
        <>
            {
                ownBots &&
                ownBots.map(
                    (ownBot, index) => (
                        <div key={index} className='listOwnBot-container' >
                            <div className='listOwnBot-header' >
                                <h2>{ownBot.name}</h2>
                                <div className='listOwnBot-header-container'>
                                    <small style={{ color: 'blue' }}>chatBotId</small>
                                    <span >{ownBot.userChatBotId}</span>

                                    <Button 
                                        className="p-button-rounded" 
                                        icon="pi pi-copy" 
                                        style={{ cursor: 'pointer' }} 
                                        onClick={() => copyClipBoard(ownBot.userChatBotId)} 
                                    />
                                </div>
                                {/* ToDo after create Ownbot chatbot not null  */}
                                {
                                    ownBot.chatBot && <p>{ownBot.chatBot.name}</p>
                                }
                                <i 
                                    className={(activeIndex.some(item => item === index)) ? 'pi pi-angle-down' : 'pi pi-angle-right'} 
                                    style={{ fontSize: '2rem', cursor: 'pointer' }} 
                                    onClick={() => handleScrollOnClick(index)} 
                                ></i>
                            </div>
                            {
                                activeIndex.some(item => item === index)
                                    ?
                                    <div>
                                        {
                                            activeEdit.some(item => item === index)
                                                ?
                                                <EditOwnBot 
                                                    ownBot={ownBot} 
                                                    handleActiveEdit={handleActiveEdit} 
                                                    handleScrollOnClick={handleScrollOnClick} 
                                                    index={index} 
                                                    editOwnBotsState={editOwnBotsState} 
                                                    deleteOwnBotsState={deleteOwnBotsState} 
                                                />
                                                :
                                                <DetailOwnBot ownBot={ownBot} >
                                                    {/* <Button label='Edit1' icon="pi pi-pencil" onClick={() => handleActiveEdit(index)} /> */}
                                                    <Button label='Edit' icon="pi pi-pencil" onClick={() => handleDialogEdit(ownBot)} />
                                                </DetailOwnBot>
                                        }
                                    </div>
                                    :
                                    null
                            }
                            <Dialog header="Edit ownBot" visible={visible} style={{width:'50%'}} onHide={handleQuitDialog}> 
                                {
                                    ownBotData && 
                                    <EditOwnBotForm  
                                        ownBot={ownBotData} 
                                        voicesProps={voicesProps} 
                                        handleQuitDialog={handleQuitDialog} 
                                        editOwnBotsState={editOwnBotsState} 
                                        deleteOwnBotsState={deleteOwnBotsState} 
                                        chatBotOptions={chatBotOptions}
                                    />
                                }
                            </Dialog>
                        </div>
                    )
                )
            }
        </>
    )
}
