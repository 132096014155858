import React, { useState, useEffect } from 'react'
import { CreateOwnBot } from './components/CreateOwnBot'
import { ListOwnBot } from './components/ListOwnBot'
// import { AudioPlayerTest } from './components/AudioPlayerTest/AudioPlayerTest';
import { AudioPlayer } from '../../components/AudioPlayer/AudioPlayer'


// services
import { editOwnBots, obtainChatBotOptions, obtainOwnBots } from './service';
import { obtainVoices } from '../../components/AudioPlayer/services'
import './styles.css'

export const OwnBot = () => {
    const [chatBotOptions, setChatBotOptions] = useState({})            // ChatBot Options
    const [ownBotList, setOwnBotList] = useState(null);                 // OwnBotList 
    const [ownBotListLoading, setOwnBotListLoading] = useState(true)   // OwnBOtListLoading

    const [voices, setVoices] = useState([]);
    const [voicesLoading, setVoicesLoading] = useState(true) // VoicesLoading

    useEffect(() => {
        // getOwnBots
        const getOwnBotList = async () => {
            setOwnBotListLoading(true);
            // const userId = JSON.parse(window.localStorage.getItem('userOwnChat'));
            // console.log(userId.id);
            try {
                // const response = await obtainOwnBots(userId.id);
                const response = await obtainOwnBots();
                const data = response;
                // console.log(data);
                setOwnBotList(data);
                setOwnBotListLoading(false);
            } catch (error) {
                setOwnBotList([]);
                setOwnBotListLoading(false)
                console.error('ObtainOwnChat Error: ', error);
            }
        }
        // getChatBotModelsOptions
        const getChatBotOptions = async () => {
            try {
                const response = await obtainChatBotOptions();
                const data = response;
                setChatBotOptions(data);
            } catch (error) {
                console.error('obtainChatBotOptions Error', error);
            }
        }

        const getVoices = async () => {
            try {
                setVoicesLoading(true);
                const response = await obtainVoices();
                const data = response;
                // console.log(data.voices);
                setVoices(data.voices);
                setVoicesLoading(false);
            } catch (error) {
                console.log(error);
            }
        }

        // callFunction
        getOwnBotList();
        getChatBotOptions();
        getVoices();
    }, [])

    const addOwnBot = (newOwnBot) => {
        // console.log(newOwnBot.data);
        setOwnBotList([...ownBotList, newOwnBot.data])
    }
    const editOwnBotsState = (editedOwnBot) => {
        let ownBotListState = ownBotList;
        ownBotListState = ownBotListState.map(ownBotState => {
            if (ownBotState.userChatBotId === editedOwnBot.userChatBotId) {
                ownBotState = editedOwnBot
            }
            return ownBotState
        });
        setOwnBotList(ownBotListState);
    }

    const deleteOwnBotsState = (userChatBotId) => {
        let ownBotListState = ownBotList;
        ownBotListState = ownBotListState.filter(ownBotState => ownBotState.userChatBotId !== userChatBotId);
        setOwnBotList(ownBotListState);
    }

    return (
        <>
            <div className='ownBotContainer'>
                {/* {
                    !voicesLoading && <CreateOwnBot addOwnBot={addOwnBot} chatBotOptions={chatBotOptions} voices={voices} />
                } */}

                {
                    voicesLoading
                        ? <div className='ownBotContainer-card' id='card-link' target="_blank">
                            <div className='ownBotContainer-card-header' style={{borderRadius:'5px'}} >
                                <div className='skeleton ownBotContainer-card-item1'></div>
                                <div className='skeleton ownBotContainer-card-item2'></div>
                                <div className='skeleton ownBotContainer-card-item1'></div>
                                <i className='pi pi-plus'></i>
                            </div>
                        </div>
                        : <CreateOwnBot addOwnBot={addOwnBot} chatBotOptions={chatBotOptions} voices={voices} />
                }


                {
                    ownBotListLoading
                        ? <div className='ownBotContainer-card' id='card-link' target="_blank">
                            <div className='ownBotContainer-card-header' >
                                <div className='skeleton ownBotContainer-card-item1'></div>
                                <div className='skeleton ownBotContainer-card-item2'></div>
                                <div className='skeleton ownBotContainer-card-item1'></div>
                                <div className='skeleton ownBotContainer-card-item1'></div>
                            </div>
                        </div>
                        :
                        ownBotList && <ListOwnBot ownBots={ownBotList} voicesProps={voices} editOwnBotsState={editOwnBotsState} deleteOwnBotsState={deleteOwnBotsState} chatBotOptions={chatBotOptions} />
                }

            </div>
        </>
    )
}
