export const languages = [
	{ language: "English", code: "en", text: "Hi I'm an ownbot, I'm here to test my voice settings" },
	{ language: "Japanese", code: "ja", text:"こんにちは、私はownbotです。音声設定をテストするためにここに来ました" },
	{ language: "Chinese", code: "zh", text: "嗨，我是一个自己的机器人，我来这里是为了测试我的语音设置" },
	{ language: "German", code: "de" ,text:"Hallo, ich bin ein Ownbot, ich bin hier, um meine Spracheinstellungen zu testen"},
	{ language: "Hindi", code: "hi" ,text:"नमस्ते, मैं एक स्वयं का बॉट हूं, मैं अपनी आवाज सेटिंग्स का परीक्षण करने के लिए यहां हूं"},
	{ language: "French", code: "fr", text:"Salut, je suis un ownbot, je suis ici pour tester mes paramètres vocaux" },
	{ language: "Korean", code: "ko",text:"안녕하세요. 저는 자체봇입니다. 음성 설정을 테스트하러 왔습니다." },
	{ language: "Portuguese", code: "pt",text:"Olá, sou um ownbot, estou aqui para testar minhas configurações de voz" },
	{ language: "Italian", code: "it",text:"Ciao, sono un ownbot, sono qui per testare le mie impostazioni vocali" },
	{ language: "Spanish", code: "es", text:"Hola soy un ownbot, estoy aquí para probar mi configuración de voz" },
	{ language: "Indonesian", code: "id",text:"Hai, saya seorang ownbot, saya di sini untuk menguji pengaturan suara saya" },
	{ language: "Dutch", code: "nl", text:"Hallo, ik ben een ownbot, ik ben hier om mijn steminstellingen te testen"},
	{ language: "Turkish", code: "tr",text:"Merhaba ben bir ownbot'um, ses ayarlarımı test etmek için buradayım" },
	{ language: "Polish", code: "pl", text:"Cześć, jestem własnym botem i jestem tutaj, aby przetestować ustawienia głosu" },
	{ language: "Swedish", code: "sv",text:"Hej, jag är en ownbot, jag är här för att testa mina röstinställningar" },
	{ language: "Bulgarian", code: "bg", text: "Здравейте, аз съм ownbot, тук съм, за да тествам гласовите настройки" },
	{ language: "Romanian", code: "ro",text:"Bună, sunt ownbot, sunt aici pentru a-mi testa setările vocii"},
	{ language: "Arabic", code: "ar", text: "مرحبًا، أنا روبوت خاص بي، وأنا هنا لاختبار إعدادات صوتي" },
	{ language: "Czech", code: "cs", text:"Ahoj, jsem ownbot, jsem tu, abych otestoval nastavení hlasu" },
	{ language: "Greek", code: "el", text:"Γεια, είμαι δικός μου ρομπότ, είμαι εδώ για να δοκιμάσω τις ρυθμίσεις φωνής μου" },
	{ language: "Finnish", code: "fi" ,text:"Hei, olen oma robotti, olen täällä testaamassa ääniasetuksiani"},
	{ language: "Croatian", code: "hr" , text:"Bok, ja sam ownbot, ovdje sam da testiram svoje glasovne postavke" },
	{ language: "Malay", code: "ms" ,text:"Hai, saya ownbot, saya di sini untuk menguji tetapan suara saya" },
	{ language: "Slovak", code: "sk",text:"Ahoj, som vlastný bot, chcem otestovať nastavenia hlasu" },
	{ language: "Danish", code: "da", text:"Hej, jeg er en ownbot, jeg er her for at teste mine stemmeindstillinger" },
	{ language: "Tamil", code: "ta" ,text:"ஹாய் நான் ஒரு சொந்த பாட், எனது குரல் அமைப்புகளைச் சோதிக்க வந்துள்ளேன்" },
	{ language: "Ukrainian", code: "uk",text:"Привіт, я ownbot, я тут, щоб перевірити свої налаштування голосу" }
]
