import React from 'react'
import { Login } from '../Login'

export const Landing = () => {
    return (
        <React.Fragment>
            <Login />
        </React.Fragment>
    )
}
