import React, { useEffect, useContext } from 'react'

//service
import { purchaseCredits } from '../../service'

// paypal service
import { PayPalScriptProvider } from '@paypal/react-paypal-js'
import { PayPalButtons } from '@paypal/react-paypal-js'

//context
import { CurrentBalanceContext } from '../../../../context/CurrentBalanceContextProvider'


export const PaypalPayment = ({ creditPack, handleQuitDialog }) => {
    const { setCurrentBalance } = useContext(CurrentBalanceContext)

    useEffect(() => {

    }, [])

    const paypalClientId = process.env.REACT_APP_CLIENT_ID


    const createPaypalOrder = (data, actions) => {
        return actions.order.create({
            purchase_units: [{
                description: `Buy OwnChat ${creditPack.packName} pack`,
                amount: {
                    currency_code: 'USD',
                    // value: "0.99"
                    value: creditPack.packPrice
                }
            }]
        });
    }

    const onApprovePaypalOrder = async (data, actions) => {
        const order = await actions.order.capture();
        // console.log("order", order);

        await onApproveTransaction();
    }

    const onApproveTransaction = async () => {
        try {
            const value = JSON.parse(window.localStorage.getItem('userOwnChat'));
            const userId = value.id;

            const purchaseApprove = {
                "creditPackId": creditPack.id,
                "userId": userId
            }

            const response = await purchaseCredits(purchaseApprove);
            setCurrentBalance(response.data.currentCreditAmount)
            await handleQuitDialog();
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <>
            <PayPalScriptProvider
                options={{
                    "client-id": paypalClientId
                }}
            >
                <PayPalButtons
                    createOrder={
                        createPaypalOrder
                    }
                    // onCancel={() => { }}
                    onApprove={
                        onApprovePaypalOrder
                    }
                />
            </PayPalScriptProvider>
        </>
    )
}
