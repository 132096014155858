import React, { useEffect, useContext, useRef } from 'react'
import { useAuth } from '../../hooks/useAuth'
import { Logout } from '../Logout'
import { useNavigate } from 'react-router'

import { TieredMenu } from 'primereact/tieredmenu'
import { Button } from 'primereact/button'

// services
import { obtainCurrentBalance } from '../../modules/Payment/service'
// context
import { CurrentBalanceContext } from '../../context/CurrentBalanceContextProvider'

//react Icons
import { AiFillDollarCircle } from "react-icons/ai";

import './styles.css'

export const Navbar = () => {
    const auth = useAuth();
    const navigate = useNavigate();
    const menu = useRef(null)

    const navItems = [
        {
            label: 'OwnBot List',
            icon: 'pi pi-fw pi-file',
            command: () => {
                navigateToOwnBotList()
            }
        },
        // {
        //     label: 'User',
        //     icon: 'pi pi-fw pi-user',
        // },
        {
            label: 'Purchase Credits',
            icon: 'pi pi-fw pi-cart-plus',
            // items: [
            //     {
            //         label: 'Left',
            //         icon: 'pi pi-fw pi-align-left'
            //     },
            //     {
            //         label: 'Right',
            //         icon: 'pi pi-fw pi-align-right'
            //     },
            //     {
            //         label: 'Center',
            //         icon: 'pi pi-fw pi-align-center'
            //     },
            //     {
            //         label: 'Justify',
            //         icon: 'pi pi-fw pi-align-justify'
            //     }
            // ]
            command: () => {
                navigateToPurchaseCredits();
            }
        },

        {
            separator: true
        },
        {
            label: 'Quit',
            icon: 'pi pi-fw pi-power-off',
            command: () => {
                logOutProfile();
            }
        }
    ];

    const { currentBalance, setCurrentBalance } = useContext(CurrentBalanceContext);


    useEffect(() => {
        const getUserCurrentBalance = async () => {
            try {
                const value = JSON.parse(window.localStorage.getItem('userOwnChat'));
                const userId = value.id;

                const response = await obtainCurrentBalance(userId);

                // console.log(response.data);
                setCurrentBalance(response.data)
            } catch (error) {
                console.log(error);
            }
        }

        getUserCurrentBalance();
    }, [])

    const navigateToOwnBotList = () => {
        navigate('/home');
    }

    const navigateToPurchaseCredits = () => {
        navigate('/payment');
    }

    const logOutProfile = () => {
        auth.logout()
    }



    return (
        <div className='navbar'>
            {
                (auth.user != null)
                    ?
                    < React.Fragment>
                        {/* <i className='pi pi-bars' style={{ fontSize: '1.5rem' }}></i> */}
                        <span>OwnChat</span>

                        <div className='navbar-Menu' >
                            <div
                                style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '0.4rem', color: '#6366F1' }}
                            >
                                <h5 className='navbar-Menu-CreditsLabel'>Credits</h5>
                                <p style={{ fontWeight: 'bold' }} >{currentBalance}</p>
                                <AiFillDollarCircle size={'1.2rem'} />
                            </div>


                            <TieredMenu model={navItems} popup ref={menu} breakpoint="767px" />
                            {/* <div className='pi pi-bars navBarMenu' onClick={(e) => menu.current.toggle(e)} ></div> */}
                            <Button icon="pi pi-bars" onClick={(e) => menu.current.toggle(e)} />

                            {/* <Logout /> */}
                        </div>
                    </React.Fragment>
                    :
                    <div>
                        <span>OwnChat</span>
                    </div>
            }
        </div>
    )
}
