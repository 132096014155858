import React, { useState } from 'react'
import { InputText } from 'primereact/inputtext'
import { Button } from 'primereact/button';

// service
import { chat } from './service';


import './styles.css'

export const Chat = () => {
    const [chatHistories, setChatHistories] = useState([
        // {
        //     user: 'user',
        //     message: 'message2'
        //     // message: 'message1message1messag message1message1message1message1message1message1message1message1 message1message1message1message1message1message1'
        // },
        {
            user: 'bot',
            message: 'Send me a message'
        },
    ]);
    const [sendLoading, setSendLoading] = useState(false)
    const [text, setText] = useState('')

    const sendSubmit = async (e) => {
        e.preventDefault();
        try {
            setSendLoading(true)
            const message = {
                user: 'user',
                message: text, 
            }
            setChatHistories(current => [...current, message ]  )
            setText('')
            const response = await chat(text)
            // console.log(response);
            if(response === 'Unknow'){
                const message2 = {
                    user: 'bot',
                    message: 'Sorry I did not understand your message '
                }
                setChatHistories(current => [...current, message2 ]  )
            }
            else{
                /*
                Chat works with
                    - I want to book a table at sherpa restaurant
                    - I want to reserve a room in monet garden
                    - I want to quote your AI services
                    - I want to send an email to customer service
                    - i want to hear enter sandman
                    - i want to get information about traffic in amsterdam
                */
                const message2 = {
                    user: 'bot',
                    message:response
                }
                setChatHistories(current => [...current, message2 ]  )
            }
            setSendLoading(false)
        } catch (error) {
            
        }
        
    }

    return (
        <div className='chatContainer'>
            <div className='chatMessages'>
                {
                    chatHistories.map((chat, index) =>
                        (chat.user === 'user')
                            ?
                            <div className='me' key={index} >
                                <small>{chat.user}</small>
                                <p className='from-me sb1'  >{chat.message}</p>
                            </div>
                            :
                            <div className='then' key={index} >
                                <small>{chat.user}</small>
                                <p className='from-then sb2' >{chat.message}</p>
                            </div>
                    )
                }
            </div>
            {/* <div > */}
                <form onSubmit={sendSubmit} className='textContainer' >
                    <InputText style={{ width: '95%' }} value={text} onChange={(e) => setText(e.target.value)} required/>
                    <Button type='submit' loading={sendLoading} >Send</Button>
                </form>
            {/* </div> */}
        </div>
    )
}
