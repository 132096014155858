import React, { useEffect, useState } from 'react'
// components
import { PaymentCard } from './components/PaymentCard/index.jsx'
//services
import { obtainPaymentPacks } from './service'
//styles
import './styles.css'


export const Payment = () => {
    const [creditPacks, setCreditPacks] = useState([]);    //CreditPacks
    const [paymentLoading, setPaymentLoading] = useState(true);


    useEffect(() => {
        // const clientEnv = process.env.REACT_APP_CLIENT_ID
        // console.log(clientEnv);
        const getCreditPacks = async () => {
            setPaymentLoading(true);

            try {
                const response = await obtainPaymentPacks();
                const data = response;

                setCreditPacks(data.data);
                setPaymentLoading(false);
            } catch (error) {
                setCreditPacks([]);
                setPaymentLoading(false);
                console.error('ObtainPaymentPacks', error);
            }
        }

        //CallFunctions
        getCreditPacks();
    }, [])

    return (
        <>
            <main className='Payment-main'>
                {
                    (paymentLoading)
                        ?
                        <div>loading</div>
                        :
                        <>
                            <h1>Add Credits to your Account </h1>
                            <div className='Payment-main-PaymentCards'>
                                {
                                    creditPacks.map((creditPack, index) =>
                                        (
                                            <div key={index} >
                                                <PaymentCard
                                                    creditPack={creditPack}
                                                />
                                            </div>
                                        )
                                    )
                                }
                            </div>
                        </>
                }
            </main>
        </>
    )
}
