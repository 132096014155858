import React from 'react'
import { ProtectedComponents } from '../../hooks/useAuth'
import { OwnBot } from '../../modules/OwnBot'
import { Chat } from '../../modules/Chat'

import './styles.css'

export const Home = () => {
    return (
        <ProtectedComponents>
            {/* <div className='homeContainer'>
                Welcome to OwnChat App
            </div> */}
            {/* <Chat/> */}
            <OwnBot />
        </ProtectedComponents>
    )
}
