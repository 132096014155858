import React, { useState } from 'react'

export const CurrentBalanceContext = React.createContext({});

export const CurrentBalanceContextProvider = ({ children }) => {
    const [currentBalance, setCurrentBalance] = useState(0);

    return (
        <CurrentBalanceContext.Provider value={{ currentBalance, setCurrentBalance }}>
            {children}
        </CurrentBalanceContext.Provider>
    )
}
